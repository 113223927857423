import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';

export const CustomPagination = ({ totalItems, itemsPerPage, currentPage, setCurrentPage, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            onPageChange(page);
        }
    };

    const generatePaginationItems = () => {
        const items = [];
        const maxVisiblePages = 5;
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (startPage > 1) {
            items.push(<Pagination.Item key={1} onClick={() => handlePageChange(1)}>{1}</Pagination.Item>);
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        for (let page = startPage; page <= endPage; page++) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            items.push(
                <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return items;
    };

    return (
        <div className='d-flex align-items-center justify-content-center mb-4'>
            <Pagination size="md">
                {/* <Pagination.First onClick={() => handlePageChange(1)} /> */}
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
                {generatePaginationItems()}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
                {/* <Pagination.Last onClick={() => handlePageChange(totalPages)} /> */}
            </Pagination>
        </div>
    );
}