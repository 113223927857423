
const RatePrice = ({ selectedRates, isQuted }) => {

    return (
        <div className="">
            <div className="row g-3 mb-2">
                <div className="col-md-2"></div>
                <div className="col-md-2 text-center">
                    <span className="font-weight-bold">Option 1</span>
                </div>
                <div className="col-md-2 text-center">
                    <span className="font-weight-bold">Option 2</span>
                </div>
                <div className="col-md-2 text-center">
                    <span className="font-weight-bold">Option 3</span>
                </div>
                <div className="col-md-2 text-center">
                    <span className="font-weight-bold">Option 4</span>
                </div>
                <div className="col-md-2 text-center">
                    <span className="font-weight-bold">Option 5</span>
                </div>
            </div>

            <div className="row g-3 mb-2">
                <div className="col-md-2 text-right">
                    <span className="font-weight-bold">Rate</span>
                </div>
                <div className="col-md-2">
                    <div className="border rounded py-1 px-2 bg-secondary text-center">
                        {selectedRates?.[0]?.rate || 0.00} %
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="border rounded py-1 px-2 bg-secondary text-center">
                        {selectedRates?.[1]?.rate || 0.00} %
                    </div>
                </div>
                <div className="col-md-2"
                >
                    <div className="border rounded py-1 px-2 bg-secondary text-center">
                        {selectedRates?.[2]?.rate || 0.00} %
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="border rounded py-1 px-2 bg-secondary text-center">
                        {selectedRates?.[3]?.rate || 0.00} %
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="border rounded py-1 px-2 bg-secondary text-center">
                        {selectedRates?.[4]?.rate || 0.00} %
                    </div>
                </div>
            </div>

            <div className="row g-3 mb-2">
                <div className="col-md-2 text-right">
                    <span className="font-weight-bold  ">Price</span>
                </div>
                <div className="col-md-2">
                    <div className="border rounded py-1 px-2 bg-secondary text-center">
                        {selectedRates?.[0]?.price || 0.00} %
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="border rounded py-1 px-2 bg-secondary text-center">
                        {selectedRates?.[1]?.price || 0.00} %
                    </div>
                </div>
                <div className="col-md-2"
                >
                    <div className="border rounded py-1 px-2 bg-secondary text-center">
                        {selectedRates?.[2]?.price || 0.00} %
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="border rounded py-1 px-2 bg-secondary text-center">
                        {selectedRates?.[3]?.price || 0.00} %
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="border rounded py-1 px-2 bg-secondary text-center">
                        {selectedRates?.[4]?.price || 0.00} %
                    </div>
                </div>
            </div>

            {isQuted &&
                <>
                    <div className="row g-3 mb-2">
                        <div className="col-md-2 text-right">
                            <span className="font-weight-bold  ">QM</span>
                        </div>
                        <div className="col-md-2">
                            <div className="border rounded py-1 px-2 bg-info text-center">
                                $2339.32
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="border rounded py-1 px-2 bg-info text-center">
                                $2339.32
                            </div>
                        </div>
                        <div className="col-md-2"
                        >
                            <div className="border rounded py-1 px-2 bg-info text-center">
                                $2339.32
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="border rounded py-1 px-2 bg-info text-center">
                                $2339.32
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="border rounded py-1 px-2 bg-info text-center">
                                $2339.32
                            </div>
                        </div>
                    </div>

                    <div className="row g-3 mb-2">
                        <div className="col-md-2 text-right">
                            <span className="font-weight-bold">Proj. Revenue</span>
                        </div>
                        <div className="col-md-2">
                            <div className="rounded py-1 px-2 text-left">
                                $2339.32
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="rounded py-1 px-2 text-left">
                                $2339.32
                            </div>
                        </div>
                        <div className="col-md-2"
                        >
                            <div className="rounded py-1 px-2 text-left">
                                $2339.32
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="rounded py-1 px-2 text-left">
                                $2339.32
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="rounded py-1 px-2 text-left">
                                $2339.32
                            </div>
                        </div>
                    </div>

                    <div className="row g-3 mb-2">
                        <div className="col-md-2 text-right">
                            <span className="font-weight-bold">Include</span>
                        </div>
                        <div className="col-md-2 px-5">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        </div>
                        <div className="col-md-2 px-5">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        </div>
                        <div className="col-md-2 px-5">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        </div>
                        <div className="col-md-2 px-5">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        </div>
                        <div className="col-md-2 px-5">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default RatePrice
