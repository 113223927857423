import { apiBaseSlices } from "./baseAPI";



export const authApi = apiBaseSlices.injectEndpoints({
    endpoints: (builder) => ({
        getSlider: builder.query({
            query: () => "/places?q=apple+inc&apiKey=7b225c3e65f5b243b79c7399d3fed04cda29a58d",
        }),

        // getAllProducts: builder.query({
        //     query: () => "products",
        // }),
    })
});

export const { useGetSliderQuery } = authApi;