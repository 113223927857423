import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useNavigate } from 'react-router-dom'
// img
import user1 from '../../../assets/images/user/1.jpg'

const fromInitialValus = {
    borrowerDetails: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        creditScore: null,
        veteran: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
    },
    subjectPropertyDetails: {
        state: "",
        zipcode: "",
        propertyValue: null,
        propertyType: "",
        occupancy: "",
        monthlyPropertyTax: null,
        monthlyInsurance: null,
        loanProgram: "",
        currentLead: "",
        currentTerm: "",
        currentRate: "0",
        currentBalance: null,
        mortgagePayment: null,
        revolvingDebt: null,
        revolvingPayment: null,
    },
}

const LeadEdit = () => {
    const [formData, setFormData] = useState(fromInitialValus);

    const navigate = useNavigate();

    const handleInputChange = (section, field, value) => {
        setFormData((prev) => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);

        navigate("/lead-edit-quote");
    };

    return (
        <>
            <Container fluid>
                <Form>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/leads">Lead</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Edit Lead</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/leads" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">Back</span>
                                </Link>
                            </div>
                        </Col>

                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">Edit Lead</h4>
                        </Col>

                        {/* Borrower Details  */}
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <div className='d-flex align-items-center mb-3'>
                                        <h4 className="font-weight-bold text-center pb-1 border-bottom border-primary m-auto">Borrower Details</h4>
                                    </div>
                                    <div className='row g-3'>
                                        <div className="col-md-6 mb-3">
                                            <Form.Group  controlId="fname">
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">First Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter First Name"
                                                    value={formData.borrowerDetails.firstName}
                                                    onChange={(e) =>
                                                        handleInputChange("borrowerDetails", "firstName", e.target.value)
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6 mb-3">

                                            <Form.Group>
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Last Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Last Name"
                                                    className="form-control"
                                                    value={formData.borrowerDetails.lastName}
                                                    onChange={(e) =>
                                                        handleInputChange("borrowerDetails", "lastName", e.target.value)
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6 mb-3">

                                            <Form.Group>
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Email"
                                                    className="form-control"
                                                    value={formData.borrowerDetails.email}
                                                    onChange={(e) =>
                                                        handleInputChange("borrowerDetails", "email", e.target.value)
                                                    }
                                                    required
                                                />

                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                <Form.Control
                                                    type="phone"
                                                    className="form-control"
                                                    placeholder="Enter Phone"
                                                    value={formData.borrowerDetails.phone}
                                                    onChange={(e) =>
                                                        handleInputChange("borrowerDetails", "phone", e.target.value)
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Credit Score</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter Credit Score"
                                                    value={formData.borrowerDetails.creditScore}
                                                    onChange={(e) =>
                                                        handleInputChange("borrowerDetails", "creditScore", e.target.value)
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6 mb-3">

                                            <Form.Group>
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Veteran</Form.Label>
                                                <select
                                                    className="form-select choicesjs form-control"
                                                    value={formData.borrowerDetails.veteran}
                                                    onChange={(e) =>
                                                        handleInputChange("borrowerDetails", "veteran", e.target.value)
                                                    }
                                                >
                                                    <option className="">Select Country</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>

                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label className="font-weight-bold text-muted text-uppercase">Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Address"
                                                    value={formData.borrowerDetails.address}
                                                    onChange={(e) =>
                                                        handleInputChange("borrowerDetails", "address", e.target.value)
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">City</Form.Label>
                                                <select
                                                    id="inputcountry"
                                                    className="form-select form-control choicesjs"
                                                    value={formData.borrowerDetails.city}
                                                    onChange={(e) =>
                                                        handleInputChange("borrowerDetails", "city", e.target.value)
                                                    }
                                                >
                                                    <option className="">Select Country</option>
                                                    <option value="USA">USA</option>
                                                    <option value="UK">UK</option>
                                                    <option value="France">France</option>
                                                </select>
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">State/Region</Form.Label>
                                                <select
                                                    id="inputState"
                                                    className="form-select form-control choicesjs"
                                                    value={formData.borrowerDetails.state}
                                                    onChange={(e) =>
                                                        handleInputChange("borrowerDetails", "state", e.target.value)
                                                    }
                                                >
                                                    <option className="">Select State/Region</option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Briston">Briston</option>
                                                    <option value="Nevada">Nevada</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="California">California</option>
                                                </select>
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label
                                                    className="font-weight-bold text-muted text-uppercase"
                                                >
                                                    Zipcode
                                                </Form.Label>
                                                <Form.Control
                                                className="form-control"
                                                    type="text"
                                                    placeholder="Enter Zipcode"
                                                    value={formData.borrowerDetails.zipcode}
                                                    onChange={(e) =>
                                                        handleInputChange("borrowerDetails", "zipcode", e.target.value)
                                                    }
                                                    required
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/*  Subject Property Details */}
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <div className='d-flex align-items-center mb-3'>
                                        <h4 className="font-weight-bold text-center pb-1 border-bottom border-primary m-auto">Subject Property Details</h4>
                                    </div>

                                    <Row>
                                        <Col md="12">
                                            <div className='row g-3'>
                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">State/Region</Form.Label>
                                                    <select
                                                        id="inputState"
                                                        className="form-select form-control choicesjs"
                                                        value={formData.subjectPropertyDetails.state}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "state", e.target.value)
                                                        }
                                                    >
                                                        <option className="">Select State/Region</option>
                                                        <option value="Ohio">Ohio</option>
                                                        <option value="Briston">Briston</option>
                                                        <option value="Nevada">Nevada</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Texas">Texas</option>
                                                        <option value="California">California</option>
                                                    </select>
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="Text7" className="font-weight-bold text-muted text-uppercase">Zipcode</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="Text7"
                                                        className="form-control"
                                                        placeholder="Enter Zipcode"
                                                        value={formData.subjectPropertyDetails.zipcode}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "zipcode", e.target.value)
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className='row g-3'>
                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="propertyValue" className="font-weight-bold text-muted text-uppercase">Property Value</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        id="propertyValue"
                                                        className="form-control"
                                                        placeholder="0"
                                                        value={formData.subjectPropertyDetails.propertyValue}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "propertyValue", e.target.value)
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="propertyType" className="font-weight-bold text-muted text-uppercase">Property Type</Form.Label>
                                                    <select
                                                        id="propertyType"
                                                        className="form-select form-control choicesjs"
                                                        value={formData.subjectPropertyDetails.propertyType}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "propertyType", e.target.value)
                                                        }
                                                    >
                                                        <option className="">Select Property Type</option>
                                                        <option value="Single Family">Single Family</option>
                                                        <option value="Multi Family">Multi Family</option>
                                                        <option value="Condominium">Condominium</option>
                                                        <option value="Townhouse">Townhouse</option>
                                                    </select>
                                                </div>

                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="Occupancy" className="font-weight-bold text-muted text-uppercase">Occupancy</Form.Label>
                                                    <select
                                                        id="Occupancy"
                                                        className="form-select form-control choicesjs"
                                                        value={formData.subjectPropertyDetails.occupancy}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "occupancy", e.target.value)
                                                        }
                                                    >
                                                        <option className="">Select Occupancy</option>
                                                        <option value="Primary">Primary</option>
                                                        <option value="test">test</option>
                                                    </select>
                                                </div>

                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="monthlyPropertyTax" className="font-weight-bold text-muted text-uppercase">Monthly Property Tax</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        id="monthlyPropertyTax"
                                                        placeholder="0"
                                                        value={formData.subjectPropertyDetails.monthlyPropertyTax}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "monthlyPropertyTax", e.target.value)
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="monthlyInsurance" className="font-weight-bold text-muted text-uppercase">Monthly Insurance</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        id="monthlyInsurance"
                                                        className="form-control"
                                                        placeholder="0"
                                                        value={formData.subjectPropertyDetails.monthlyInsurance}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "monthlyInsurance", e.target.value)
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className='row g-3'>
                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="loanProgram" className="font-weight-bold text-muted text-uppercase">Current Loan Program</Form.Label>
                                                    <select
                                                        id="loanProgram"
                                                        className="form-select form-control choicesjs"
                                                        value={formData.subjectPropertyDetails.loanProgram}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "loanProgram", e.target.value)
                                                        }
                                                    >
                                                        <option className="">Select Current Loan Program</option>
                                                        <option value="FHA">FHA</option>
                                                        <option value="FHA">FHA</option>
                                                    </select>
                                                </div>

                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="currentLead" className="font-weight-bold text-muted text-uppercase">Current Lead</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="currentLead"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        value={formData.subjectPropertyDetails.currentLead}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "currentLead", e.target.value)
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="currentTerm" className="font-weight-bold text-muted text-uppercase">Current Term</Form.Label>
                                                    <select
                                                        id="currentTerm"
                                                        className="form-select form-control choicesjs"
                                                        value={formData.subjectPropertyDetails.currentTerm}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "currentTerm", e.target.value)
                                                        }
                                                    >
                                                        <option className="">Select Current Term</option>
                                                        <option value="F5yrHA">5yr Fixed</option>
                                                        <option value="F5yrHA">5yr Fixed</option>
                                                    </select>
                                                </div>

                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="currentRate" className="font-weight-bold text-muted text-uppercase">Current Rate (%)</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="form-control"
                                                        id="currentRate"
                                                        placeholder="Enter Current Rate"
                                                        value={formData.subjectPropertyDetails.currentRate}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "currentRate", e.target.value)
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="currentBalance" className="font-weight-bold text-muted text-uppercase">Current Balance</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        id="currentBalance"
                                                        className="form-control"
                                                        placeholder="Enter Current Balance"
                                                        value={formData.subjectPropertyDetails.currentBalance}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "currentBalance", e.target.value)
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="mortgagePayment" className="font-weight-bold text-muted text-uppercase">Mortgage Payment</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        id="mortgagePayment"
                                                        className="form-control"
                                                        placeholder="Enter Mortgage Payment"
                                                        value={formData.subjectPropertyDetails.mortgagePayment}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "mortgagePayment", e.target.value)
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className='row g-3'>
                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="revolvingDebt" className="font-weight-bold text-muted text-uppercase">Revolving Debt</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        id="revolvingDebt"
                                                        className="form-control"
                                                        placeholder="Enter Revolving Debt"
                                                        value={formData.subjectPropertyDetails.revolvingDebt}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "revolvingDebt", e.target.value)
                                                        }
                                                        required
                                                    />
                                                </div>

                                                <div className="col-md-6 col-lg-4 mb-3">
                                                    <Form.Label htmlFor="revolvingPayment" className="font-weight-bold text-muted text-uppercase">Revolving Payment</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        id="revolvingPayment"
                                                        className="form-control"
                                                        placeholder="Enter Revolving Payment"
                                                        value={formData.subjectPropertyDetails.revolvingPayment}
                                                        onChange={(e) =>
                                                            handleInputChange("subjectPropertyDetails", "revolvingPayment", e.target.value)
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>

                <div className="d-flex flex-wrap justify-content-end mt-0 mb-5">
                    <Button type="submit" onClick={handleSubmit}variant="btn btn-primary font-weight-bold btn-sm py-2 px-3">
                        Quote
                    </Button>
                </div>
            </Container>
        </>
    )
}

export default LeadEdit;
