import React, { useState } from 'react'
import { Container, Row, Col, Nav, Tab, Form, Button } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'

//img 
import user1 from '../../../../assets/images/user/1.jpg'

const personalInfoInitialValus = {
    fristName: '',
    lastname: '',
    email: '',
    phone: '',
    dateOfBirth: '',
    gender: '',
    address: '',
    imageUrl: ''
}

const changePassoeardInitialValus = {
    currentPassword: '',
    newPassword: '',
    verifyPassword: '',
}

const ProfileEdit = () => {
    const [formData, setFormData] = useState(personalInfoInitialValus);
    const [changePassowardData, setChangePassowardData] = useState(changePassoeardInitialValus);

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const inputPasswordChange = (field, value) => {
        setChangePassowardData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const onSubmit = (e) => {
        // e.preventDefault(); // Prevents the default form submission behavior
        console.log(formData); // Logs the form data
    };

    const handleChangePassoward= (e) =>{
        e.preventDefault(); // Prevents the default form submission behavior
        console.log(changePassowardData); 
    }

    return (
        <>
            <Container fluid>
                <Tab.Container defaultActiveKey="personal-information">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body className="p-0 text-center">
                                    <div className="mm-edit-list usr-edit text-center">
                                        <Nav variant="pills" className="mm-edit-profile d-flex">
                                            <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="personal-information">Personal Information</Nav.Link>
                                            </li>
                                            <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="chang-pwd">Change Password</Nav.Link>
                                            </li>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col lg="12">
                            <div className="mm-edit-list-data">
                                <Tab.Content>
                                    <Tab.Pane eventKey="personal-information" role="tabpanel">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Personal Information</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form onClick={onSubmit}>
                                                    <Row className="form-group  align-items-center">
                                                        <Col md="12">
                                                            <div className="profile-img-edit">
                                                                <div className="crm-profile-img-edit">
                                                                    <img className="crm-profile-pic rounded-circle avatar-100" src={user1} alt="profile-pic" />
                                                                    <div className="crm-p-image bg-primary">
                                                                        <i className="las la-pen upload-button"></i>
                                                                        <input className="file-upload" type="file" accept="image/*" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className=" align-items-center">
                                                        <Form.Group controlId="fname" as={Col} sm="6" >
                                                            <Form.Label>First Name:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder='Enter First Name'
                                                                required
                                                                value={formData.fristName}
                                                                onChange={(e) =>
                                                                    handleInputChange("fristName", e.target.value)
                                                                }
                                                            />
                                                        </Form.Group>

                                                        <Form.Group controlId="lname" as={Col} sm="6">
                                                            <Form.Label>Last Name:</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder='Enter Last Name'
                                                                required
                                                                value={formData.lastname}
                                                                onChange={(e) =>
                                                                    handleInputChange("lastname", e.target.value)
                                                                }
                                                            />
                                                        </Form.Group>

                                                        <Form.Group controlId="email" as={Col} sm="6">
                                                            <Form.Label>Email:</Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                placeholder='Enter Email'
                                                                required
                                                                value={formData.email}
                                                                onChange={(e) =>
                                                                    handleInputChange("email", e.target.value)
                                                                }
                                                            />
                                                        </Form.Group>

                                                        <Form.Group controlId="phone" as={Col} sm="6">
                                                            <Form.Label>Phone:</Form.Label>
                                                            <Form.Control
                                                                type="phone"
                                                                placeholder='Enter Phone'
                                                                required
                                                                value={formData.phone}
                                                                onChange={(e) =>
                                                                    handleInputChange("phone", e.target.value)
                                                                }
                                                            />
                                                        </Form.Group>

                                                        <Form.Group as={Col} sm="6" controlId="dob">
                                                            <Form.Label>Date Of Birth:</Form.Label>
                                                            <Form.Control
                                                                type='date'
                                                                required
                                                                value={formData.dateOfBirth}
                                                                onChange={(e) =>
                                                                    handleInputChange("dateOfBirth", e.target.value)
                                                                }
                                                            />
                                                        </Form.Group>

                                                        <Form.Group as={Col} sm="6">
                                                            <Form.Label bsPrefix="d-block">Gender:</Form.Label>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <Form.Check.Input type="radio" id="customRadio6" name="customRadio1" bsPrefix="custom-control-input" defaultChecked />
                                                                <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customRadio6"> Male </Form.Check.Label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <Form.Check.Input type="radio" id="customRadio7" name="customRadio1" bsPrefix="custom-control-input" />
                                                                <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customRadio7"> Female </Form.Check.Label>
                                                            </div>
                                                        </Form.Group>

                                                        <Form.Group as={Col} sm="12">
                                                            <Form.Label>Address:</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                name="address"
                                                                rows="5"
                                                                value={formData.address}
                                                                onChange={(e) =>
                                                                    handleInputChange("address", e.target.value)
                                                                }
                                                                style={{ lineHeight: "22px" }}>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Row>
                                                    {/* <Button type="reset" variant="outline-primary mr-2">Cancel</Button> */}
                                                    <Button type="submit" variant="primary">Submit</Button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="chang-pwd" role="tabpanel">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Change Password</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form onClick={handleChangePassoward}>
                                                    <Form.Group controlId="cpass">
                                                        <Form.Label>Current Password:</Form.Label>
                                                        {/* <Link to="/" onClick={e => e.preventDefault()} className="float-right">Forgot Password</Link> */}
                                                        <Form.Control
                                                            type="Password"
                                                            required
                                                            value={changePassowardData.currentPassword}
                                                            onChange={(e) =>
                                                                inputPasswordChange("currentPassword", e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="npass">
                                                        <Form.Label>New Password:</Form.Label>
                                                        <Form.Control
                                                            type="Password"
                                                            required
                                                            value={changePassowardData.newPassword}
                                                            onChange={(e) =>
                                                                inputPasswordChange("newPassword", e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="vpass">
                                                        <Form.Label>Verify Password:</Form.Label>
                                                        <Form.Control
                                                            type="Password"
                                                            required
                                                            value={changePassowardData.verifyPassword}
                                                            onChange={(e) =>
                                                                inputPasswordChange("verifyPassword", e.target.value)
                                                            }
                                                        />
                                                    </Form.Group>
                                                    <Button type="reset" variant="outline-primary mr-2">Cancel</Button>
                                                    <Button type="submit" variant="primary">Submit</Button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    )
}

export default ProfileEdit;