import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../../components/Card";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const initialValus = {
  email: '',
  passowrd: '',
}

const SignIn = (props) => {
  const [formData, setFormData] = useState(initialValus);

  const navigate = useNavigate();

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    console.log("Form Data:", formData);

    navigate("/");
  };

  return (
    <>
      <section className="login-content">
        <Container className="h-100">
          <Row className="align-items-center justify-content-center h-100">
            <Col md="5">
              <Card className="p-3">
                <Card.Body>
                  <h3 className="mb-3 font-weight-bold text-center">Sign In</h3>

                  <Form onSubmit={onSubmit}>
                    <Row>
                      <Col lg="12">
                        <Form.Group controlId="email">
                          <Form.Label
                            className="text-secondary"
                          >
                            Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            required
                            value={formData.email}
                            onChange={(e) =>
                              handleInputChange("email", e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="12" className="mt-2">
                        <Form.Group>
                          <div className="d-flex justify-content-between align-items-center">
                            <Form.Label className="text-secondary">
                              Password
                            </Form.Label>
                            <Form.Label>
                              <Link to="/auth/recoverpw">Forgot Password?</Link>
                            </Form.Label>
                          </div>
                          <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            required
                            value={formData.passowrd}
                            onChange={(e) =>
                              handleInputChange("passowrd", e.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button type="submit" className="btn btn-primary btn-block mt-2">
                      Log In
                    </Button>
                    <Col lg="12" className="mt-3">
                      <p className="mb-0 text-center">
                        Don't have an account?{" "}
                        <Link to="/auth/sign-up">Sign Up</Link>
                      </p>
                    </Col>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default connect(mapStateToProps)(SignIn);
