import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
// img


import User1 from '../../../assets/images/user/1.jpg'
import { CustomPagination } from '../../../components/utility/CustomPagination'
import { useGetSliderQuery } from '../../../api/authApiSlices'

const leads = [
    {
        leadId: "fsfs-4324-erw-342342",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
        // img:`${User1}`,
        // email:'christian.Bale@blueberrye.com',
        // location:'Ohio, USA',
        // phone:'+1 (021) 145-2256'
    },
    {
        leadId: "5353-gdfg-4534-rer",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "fsfs-4324-erw-342342",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "5353-gdfg-4534-rer",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "fsfs-4324-erw-342342",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "5353-gdfg-4534-rer",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "fsfs-4324-erw-342342",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "5353-gdfg-4534-rer",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "fsfs-4324-erw-342342",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "5353-gdfg-4534-rer",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "5353-gdfg-4534-rer",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "fsfs-4324-erw-342342",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "5353-gdfg-4534-rer",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "fsfs-4324-erw-342342",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "5353-gdfg-4534-rer",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "fsfs-4324-erw-342342",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "5353-gdfg-4534-rer",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "fsfs-4324-erw-342342",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
    {
        leadId: "5353-gdfg-4534-rer",
        name: 'Christian Bale',
        status: 'Lead',
        lastQuoted: "07/11/2024",
        branch: 'Avalon Mortgage test',
        loanOfficer: 'Maya test',
    },
]

const colums = [
    {
        lavel: "Lead ID",
        key: "leadId"
    },
    {
        lavel: "Name",
        key: "name"
    },
    {
        lavel: "Status",
        key: "status"
    },
    {
        lavel: "Last Quoted",
        key: "lastQuoted"
    },
    {
        lavel: "Branch",
        key: "branch"
    },
    {
        lavel: "Loan Officer",
        key: "loanOfficer"
    },
    {
        lavel: "Action",
        key: "action"
    }
]

const Lead = () => {
    const [totalItems, setTotalItems] = useState(leads?.length || 0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [paginatedLeads, setPaginatedLeads] = useState([]);

    const { data: wishData, lodding, error } = useGetSliderQuery();

    console.log(error);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const slicedLeads = leads.slice(startIndex, endIndex);

        setPaginatedLeads(slicedLeads);
    }, [currentPage, itemsPerPage, leads]);



    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Lead List</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">
                                        <Form className="mr-3 position-relative">
                                            <Form.Group className="mb-0">
                                                <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Search Lead" />
                                                <Link to="#" className="search-link">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                    </svg>
                                                </Link>
                                            </Form.Group>
                                        </Form>
                                        <Link to="/customer-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Add Lead
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch border-back">
                                    <Card.Body className="p-0">
                                        <div className="table-responsive">
                                            <table className="table data-table mb-0">
                                                <thead className="table-color-heading">
                                                    <tr className="">
                                                        {colums.map((col, i) => (
                                                            <th index={i} scope="col">
                                                                {col.lavel}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        paginatedLeads?.map((row, index) => (
                                                            <tr key={index} className="white-space-no-wrap">
                                                                {colums.map((col) => (
                                                                    <td>
                                                                        {
                                                                            col?.key === 'action' ?
                                                                                <div className="d-flex justify-content-center align-items-center">
                                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                                        <Link className="" to="/lead-edit">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                                                            </svg>
                                                                                        </Link>
                                                                                    </OverlayTrigger>
                                                                                </div>
                                                                            : row?.[col?.key]
                                                                        }
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12" className='text-center'>
                                <CustomPagination
                                    totalItems={totalItems}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    onPageChange={onPageChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Lead;