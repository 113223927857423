import { Form } from "react-bootstrap"
import CardWrapper from "../utility/CardwWrappper";

const LoanDetails = ({ formData, setFormData }) => {

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    return (
        <Form className=" date-icon-set-modal">
            <div className='row g-3'>
                <div className='col-md-4 col-lg-3 mb-3'>
                    <Form.Label htmlFor="lonePurpose" className="font-weight-bold text-muted text-uppercase">Loan Purpose</Form.Label>
                    <select
                        id="lonePurpose"
                        className="form-select form-control choicesjs"
                        value={formData.lonePurpose}
                        onChange={(e) =>
                            handleInputChange("lonePurpose", e.target.value)
                        }
                    >
                        <option className="">Select Country</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>

            <div className='row g-3'>
                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="loneProgram" className="font-weight-bold text-muted text-uppercase">Lone Program</Form.Label>
                    <select
                        id="loneProgram"
                        className="form-select form-control choicesjs"
                        value={formData.loneProgram}
                        onChange={(e) =>
                            handleInputChange("loneProgram", e.target.value)
                        }
                    >
                        <option className="">Select Lone Program</option>
                        <option value="FHA">FHA</option>
                        <option value="FHA">FHA</option>
                    </select>
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="financeTearm" className="font-weight-bold text-muted text-uppercase">Finance Tearm</Form.Label>
                    <select
                        id="financeTearm"
                        className="form-select form-control choicesjs"
                        value={formData.financeTearm}
                        onChange={(e) =>
                            handleInputChange("financeTearm", e.target.value)
                        }
                    >
                        <option className="">Select Finance Tearm</option>
                        <option value="5yr">5yr Fixed</option>
                        <option value="30yr">30yr Fixed</option>
                    </select>
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="lender" className="font-weight-bold text-muted text-uppercase">Lender</Form.Label>
                    <select
                        id="lender"
                        className="form-select form-control choicesjs"
                        value={formData.lender}
                        onChange={(e) =>
                            handleInputChange("lender", e.target.value)
                        }
                    >
                        <option className="">Select Lender</option>
                        <option value="Village">Village</option>
                        <option value="Village">Village</option>
                    </select>
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="baseLoanAmount" className="font-weight-bold text-muted text-uppercase">Base Loan Amount</Form.Label>
                    <Form.Control
                        type="number"
                        id="baseLoanAmount"
                        placeholder="Enter Last Name"
                        value={formData.baseLoanAmount}
                        onChange={(e) =>
                            handleInputChange("baseLoanAmount", e.target.value)
                        }
                    />
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="cashOut" className="font-weight-bold text-muted text-uppercase">Cash Out</Form.Label>
                    <Form.Control
                        type="number"
                        id="cashOut"
                        placeholder="Enter Cash Out"
                        value={formData.cashOut}
                        onChange={(e) =>
                            handleInputChange("cashOut", e.target.value)
                        }
                    />
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="loanAmountFixed" className="font-weight-bold text-muted text-uppercase">Loan Amount Fixed</Form.Label>
                    <select
                        id="loanAmountFixed"
                        className="form-select form-control choicesjs"
                        value={formData.loanAmountFixed}
                        onChange={(e) =>
                            handleInputChange("loanAmountFixed", e.target.value)
                        }
                    >
                        <option className="">Select Loan Amount Fixed</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="reserveMounts" className="font-weight-bold text-muted text-uppercase">Reserve Mounts</Form.Label>
                    <Form.Control
                        type="number"
                        id="reserveMounts"
                        placeholder="Enter Reserve Mounts"
                        value={formData.reserveMounts}
                        onChange={(e) =>
                            handleInputChange("reserveMounts", e.target.value)
                        }
                    />
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="processingFee" className="font-weight-bold text-muted text-uppercase">Processing Fee</Form.Label>
                    <Form.Control
                        type="number"
                        id="processingFee"
                        placeholder="Enter Processing Fee"
                        value={formData.processingFee}
                        onChange={(e) =>
                            handleInputChange("processingFee", e.target.value)
                        }
                    />
                </div>
            </div>

            <hr />

            <div className='row g-3'>
                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="revenueType" className="font-weight-bold text-muted text-uppercase">Revenue Type</Form.Label>
                    <select
                        id="revenueType"
                        className="form-select form-control choicesjs"
                        value={formData.revenueType}
                        onChange={(e) =>
                            handleInputChange("revenueType", e.target.value)
                        }
                    >
                        <option className="">Select Revenue Type</option>
                        <option value="Basis Points">Basis Points</option>
                        <option value="Basis Points">Basis Points</option>
                    </select>
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="BPS" className="font-weight-bold text-muted text-uppercase">Select BPS</Form.Label>
                    <Form.Control
                        type="number"
                        id="BPS"
                        placeholder="Enter BPS"
                        value={formData.BPS}
                        onChange={(e) =>
                            handleInputChange("BPS", e.target.value)
                        }
                    />
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="compensationType" className="font-weight-bold text-muted text-uppercase">Compensation Type</Form.Label>
                    <select
                        id="compensationType"
                        className="form-select form-control choicesjs"
                        value={formData.compensationType}
                        onChange={(e) =>
                            handleInputChange("compensationType", e.target.value)
                        }
                    >
                        <option className="">Select Compensation Type</option>
                        <option value="Borrower paid">Borrower paid</option>
                        <option value="Borrower paid">3Borrower paid</option>
                    </select>
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="lockTerm" className="font-weight-bold text-muted text-uppercase">Lock Term</Form.Label>
                    <select
                        id="lockTerm"
                        className="form-select form-control choicesjs"
                        value={formData.lockTerm}
                        onChange={(e) =>
                            handleInputChange("lockTerm", e.target.value)
                        }
                    >
                        <option className="">Select Lock Term</option>
                        <option value="45">45 Days</option>
                        <option value="30">30 Days</option>
                    </select>
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="isPrice" className="font-weight-bold text-muted text-uppercase">Fees in Price</Form.Label>
                    <select
                        id="isPrice"
                        className="isPorm-select form-control choicesjs"
                        value={formData.isPrice}
                        onChange={(e) =>
                            handleInputChange("isPrice", e.target.value)
                        }
                    >
                        <option className="">Select is in Price</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="parOptionAvailable" className="font-weight-bold text-muted text-uppercase">Par Option Available</Form.Label>
                    <select
                        id="parOptionAvailable"
                        className="isPorm-select form-control choicesjs"
                        value={formData.parOptionAvailable}
                        onChange={(e) =>
                            handleInputChange("parOptionAvailable", e.target.value)
                        }
                    >
                        <option className="">Select Par Option Available</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>

                <div className="col-md-4 col-lg-3 mb-3">
                    <Form.Label htmlFor="APOR" className="font-weight-bold text-muted text-uppercase">APOR (30yr)</Form.Label>
                    <Form.Control
                        type="number"
                        id="APOR"
                        placeholder="Enter APOR"
                        value={formData.APOR}
                        onChange={(e) =>
                            handleInputChange("APOR", e.target.value)
                        }
                    />
                </div>
            </div>
        </Form>
    )
}

export default LoanDetails
