import React, { useState } from 'react'
import { Container, Row, Col, Tab, Form, Button } from 'react-bootstrap'

//img 
import user1 from '../../../assets/images/user/1.jpg'
import Card from '../../../components/Card'

const initialValus = {
    fristName: '',
    lastname: '',
    email: '',
    phone: '',
    role: '',
    mannager: '',
    status: '',
    dateOfBirth: '',
    gender: '',
    address: '',
    imageUrl: ''
}

const UsersEdit = () => {
    const [formData, setFormData] = useState(initialValus);

    const handleInputChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault(); 

        console.log("Form Data:", formData);
    };

    return (
        <>
            <Container fluid>
                <Tab.Container defaultActiveKey="personal-information">
                    <Row>

                        <Col lg="12">
                            <Card>
                                <Card.Header>
                                    <Card.Header.Title>
                                        <h4 className="card-title">User Information</h4>
                                    </Card.Header.Title>
                                </Card.Header>

                                <Card.Body>
                                    <Form onSubmit={onSubmit}>
                                        <Row className="form-group  align-items-center">
                                            <Col md="12">
                                                <div className="profile-img-edit">
                                                    <div className="crm-profile-img-edit">
                                                        <img className="crm-profile-pic rounded-circle avatar-100" src={user1} alt="profile-pic" />
                                                        <div className="crm-p-image bg-primary">
                                                            <i className="las la-pen upload-button"></i>
                                                            <input className="file-upload" type="file" accept="image/*" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className=" align-items-center">
                                            <Form.Group controlId="fname" as={Col} sm="6" >
                                                <Form.Label>First Name:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder='Enter First Name'
                                                    required
                                                    value={formData.fristName}
                                                    onChange={(e) =>
                                                        handleInputChange("fristName", e.target.value)
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="lname" as={Col} sm="6">
                                                <Form.Label>Last Name:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder='Enter Last Name'
                                                    required
                                                    value={formData.lastname}
                                                    onChange={(e) =>
                                                        handleInputChange("lastname", e.target.value)
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="email" as={Col} sm="6">
                                                <Form.Label>Email:</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder='Enter Email'
                                                    required
                                                    value={formData.email}
                                                    onChange={(e) =>
                                                        handleInputChange("email", e.target.value)
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="phone" as={Col} sm="6">
                                                <Form.Label>Phone:</Form.Label>
                                                <Form.Control
                                                    type="phone"
                                                    placeholder='Enter Phone'
                                                    required
                                                    value={formData.phone}
                                                    onChange={(e) =>
                                                        handleInputChange("phone", e.target.value)
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="role" as={Col} sm="6">
                                                <Form.Label>Role:</Form.Label>
                                                <Form.Control
                                                    type="role"
                                                    placeholder='Enter Role'
                                                    required
                                                    value={formData.role}
                                                    onChange={(e) =>
                                                        handleInputChange("role", e.target.value)
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="mannager" as={Col} sm="6">
                                                <Form.Label>Mannager:</Form.Label>
                                                <Form.Control
                                                    type="mannager"
                                                    placeholder='Enter Mannager'
                                                    required
                                                    value={formData.mannager}
                                                    onChange={(e) =>
                                                        handleInputChange("mannager", e.target.value)
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} sm="6" controlId="dob">
                                                <Form.Label>Date Of Birth:</Form.Label>
                                                <Form.Control
                                                    type='date'
                                                    required
                                                    value={formData.dateOfBirth}
                                                    onChange={(e) =>
                                                        handleInputChange("dateOfBirth", e.target.value)
                                                    }
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} sm="6">
                                                <Form.Label bsPrefix="d-block">Gender:</Form.Label>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <Form.Check.Input type="radio" id="customRadio6" name="customRadio1" bsPrefix="custom-control-input" defaultChecked />
                                                    <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customRadio6"> Male </Form.Check.Label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <Form.Check.Input type="radio" id="customRadio7" name="customRadio1" bsPrefix="custom-control-input" />
                                                    <Form.Check.Label bsPrefix="custom-control-label" htmlFor="customRadio7"> Female </Form.Check.Label>
                                                </div>
                                            </Form.Group>

                                            <Form.Group as={Col} sm="12">
                                                <Form.Label>Address:</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="address"
                                                    rows="5"
                                                    value={formData.address}
                                                    onChange={(e) =>
                                                        handleInputChange("address", e.target.value)
                                                    }
                                                    style={{ lineHeight: "22px" }}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Row>
                                        {/* <Button type="reset" variant="outline-primary mr-2">Cancel</Button> */}
                                        <Button type="submit" variant="primary">Submit</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    )
}

export default UsersEdit