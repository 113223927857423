import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LeadeView = () => {

    const handleAdjust = () =>{

    }

    const handleAccept = () =>{

    }

    return (
        <Container fluid>
            <Row>
                <Col lg="12">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb p-0 mb-0">
                                    <li className="breadcrumb-item"><Link to="/leads">Lead > Preview</Link></li>
                                </ol>
                            </nav>
                        </div>
                        <Link to="/lead-edit-quote" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                            </svg>
                            <span className="ml-2">Back</span>
                        </Link>
                    </div>
                </Col>

                <Col sm={12}>
                    <p className='text-center' style={{ fontSize: '17px' }}>
                        <strong>Lead ID</strong>: 543287
                        <strong className='ml-3'>Lead Name</strong>: Fahim Joy
                    </p>
                </Col>

                <div className="table-responsive mb-5 border rounded">
                    <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                            <tr className="">
                                <th scope="col">

                                </th>
                                <th scope="col">
                                    Option 1
                                </th>
                                <th scope="col">
                                    Option 2
                                </th>
                                <th scope="col">
                                    Option 3
                                </th>
                                <th scope="col">
                                    Option 4
                                </th>
                                <th scope="col">
                                    Option 5
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Loan Type</strong>
                                </td>
                                <td>
                                    FHA
                                </td>
                                <td>
                                    FHA
                                </td>
                                <td>
                                    FHA
                                </td>
                                <td>
                                    FHA
                                </td>
                                <td>
                                    FHA
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Term</strong>
                                </td>
                                <td>
                                    30yr Fixed
                                </td>
                                <td>
                                    30yr Fixed
                                </td>
                                <td>
                                    30yr Fixed
                                </td>
                                <td>
                                    30yr Fixed
                                </td>
                                <td>
                                    30yr Fixed
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Rate</strong>
                                </td>
                                <td>
                                    4.20%
                                </td>
                                <td>
                                    4.20%
                                </td>
                                <td>
                                    4.20%
                                </td>
                                <td>
                                    4.20%
                                </td>
                                <td>
                                    4.20%
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Loan Amount</strong>
                                </td>
                                <td>
                                    $4234324
                                </td>
                                <td>
                                    $4535335
                                </td>
                                <td>
                                    $6456466
                                </td>
                                <td>
                                    $7567575
                                </td>
                                <td>
                                    $3244244
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>APR</strong>
                                </td>
                                <td>
                                    4.20%
                                </td>
                                <td>
                                    4.20%
                                </td>
                                <td>
                                    4.20%
                                </td>
                                <td>
                                    4.20%
                                </td>
                                <td>
                                    4.20%
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Loan-to-Value</strong>
                                </td>
                                <td>
                                    40.20%
                                </td>
                                <td>
                                    40.20%
                                </td>
                                <td>
                                    40.20%
                                </td>
                                <td>
                                    40.20%
                                </td>
                                <td>
                                    40.20%
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td className='text-center' colSpan={6} style={{ background: "#f1f4ff" }}>
                                    <strong>Monthly payments</strong>
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Estimated payments</strong>
                                </td>
                                <td>
                                    $4234324
                                </td>
                                <td>
                                    $4535335
                                </td>
                                <td>
                                    $6456466
                                </td>
                                <td>
                                    $7567575
                                </td>
                                <td>
                                    $3244244
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>PMI</strong>
                                </td>
                                <td>
                                    $4234324
                                </td>
                                <td>
                                    $4535335
                                </td>
                                <td>
                                    $6456466
                                </td>
                                <td>
                                    $7567575
                                </td>
                                <td>
                                    $3244244
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Estimated Escrow</strong>
                                </td>
                                <td>
                                    $4234324
                                </td>
                                <td>
                                    $4535335
                                </td>
                                <td>
                                    $6456466
                                </td>
                                <td>
                                    $7567575
                                </td>
                                <td>
                                    $3244244
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Principle & Interest</strong>
                                </td>
                                <td>
                                    $4234324
                                </td>
                                <td>
                                    $4535335
                                </td>
                                <td>
                                    $6456466
                                </td>
                                <td>
                                    $7567575
                                </td>
                                <td>
                                    $3244244
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td className='text-center' colSpan={6} style={{ background: "#f1f4ff" }}>
                                    <strong>Cash Out Details</strong>
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Rate Cost or Credit</strong>
                                </td>
                                <td>
                                    $4234324
                                </td>
                                <td>
                                    $4535335
                                </td>
                                <td>
                                    $6456466
                                </td>
                                <td>
                                    $7567575
                                </td>
                                <td>
                                    $3244244
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Esrow Reserves</strong>
                                </td>
                                <td>
                                    $4234324
                                </td>
                                <td>
                                    $4535335
                                </td>
                                <td>
                                    $6456466
                                </td>
                                <td>
                                    $7567575
                                </td>
                                <td>
                                    $3244244
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Cash to you</strong>
                                </td>
                                <td>
                                    $4234324
                                </td>
                                <td>
                                    $4535335
                                </td>
                                <td>
                                    $6456466
                                </td>
                                <td>
                                    $7567575
                                </td>
                                <td>
                                    $3244244
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Principle & Interest</strong>
                                </td>
                                <td>
                                    $4234324
                                </td>
                                <td>
                                    $4535335
                                </td>
                                <td>
                                    $6456466
                                </td>
                                <td>
                                    $7567575
                                </td>
                                <td>
                                    $3244244
                                </td>
                            </ tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive mb-5 border rounded">
                    <table className="table data-table mb-0">
                        <thead className="table-color-heading">
                            <th className='text-center' scope="col" colSpan={2}>
                                Lender & Third Party Fees
                            </th>
                            <th className='text-center' scope="col" colSpan={2}>
                                Loan Detaisl
                            </th>
                        </thead>
                        <tbody>
                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Underwriting</strong>
                                </td>
                                <td >
                                    Waived
                                </td>
                                <td className=''>
                                    <strong>Property Value</strong>
                                </td>
                                <td>
                                    $74838423
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Processing</strong>
                                </td>
                                <td>
                                    Waived
                                </td>
                                <td>
                                    <strong>Current Blance</strong>
                                </td>
                                <td>
                                    $74838423
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Appraisal (if required)</strong>
                                </td>
                                <td>
                                    $74838423
                                </td>
                                <td>
                                    <strong>State</strong>
                                </td>
                                <td>
                                    AK
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Credit & Verifications</strong>
                                </td>
                                <td>
                                    $74838423
                                </td>
                                <td>
                                    <strong>Loan Purpose</strong>
                                </td>
                                <td>
                                    Refinance
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Tlitle Changes (services you can shop for)</strong>
                                </td>
                                <td>

                                </td>
                                <td>
                                    <strong>Locak Tem</strong>
                                </td>
                                <td>
                                    30 Days
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Title changes & services</strong>
                                </td>
                                <td>
                                    $748
                                </td>
                                <td>
                                    <strong>Occupancy</strong>
                                </td>
                                <td>
                                    Primary
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong>Leander and Third Party Fees</strong>
                                </td>
                                <td>
                                    $748423
                                </td>
                                <td>
                                    <strong>Property Type</strong>
                                </td>
                                <td>
                                    Single Family
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong></strong>
                                </td>
                                <td>

                                </td>
                                <td>
                                    <strong>Escrow Waiver</strong>
                                </td>
                                <td>
                                    NO
                                </td>
                            </ tr>

                            <tr className="white-space-no-wrap">
                                <td>
                                    <strong></strong>
                                </td>
                                <td>

                                </td>
                                <td>
                                    <strong>Credit Score</strong>
                                </td>
                                <td>
                                    2342
                                </td>
                            </ tr>
                        </tbody>
                    </ table>
                </ div>

                <div className='row justify-content-end mb-5' style={{width: "100%"}}>
                    <Button onClick={handleAdjust} variant="outline-primary  font-weight-bold btn-sm py-2 px-3 mr-2">
                        Adjust
                    </Button>

                    <Button onClick={handleAccept} variant="btn btn-primary font-weight-bold btn-sm py-2 px-3">
                        Accept
                    </Button>
                </div>

            </ Row>
        </ Container>
    )
}

export default LeadeView;
