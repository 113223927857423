import { Card } from "react-bootstrap";

const CardWrapper = ({ children, title }) => {
    return (
        <Card>
            <Card.Body>
                <div className='d-flex align-items-center mb-3'>
                    <h4 className="font-weight-bold text-center pb-1 border-bottom border-primary m-auto">{title}</h4>
                </div>
                <div>{children}</div>
            </Card.Body>
        </Card>
    );
};

export default CardWrapper;