import { Card } from "react-bootstrap";
import CardWrapper from "../utility/CardwWrappper";

const rateStackData = [
    {
        id: 1,
        purpose: "Refinance",
        program: "FHA",
        creditBucket: "500-699",
        leader: "Village",
        rate: 5.5,
        price: 76.344,
        lockTerm: 39
    },
    {
        id: 2,
        purpose: "Refinance",
        program: "FHA",
        creditBucket: "500-699",
        leader: "Village",
        rate: 6.03,
        price: 98.234,
        lockTerm: 45
    },
    {
        id: 3,
        purpose: "Refinance",
        program: "FHA",
        creditBucket: "500-699",
        leader: "Village",
        rate: 7.32,
        price: 23.972,
        lockTerm: 45
    },
    {
        id: 4,
        purpose: "Refinance",
        program: "FHA",
        creditBucket: "500-699",
        leader: "Village",
        rate: 7,
        price: 98.32,
        lockTerm: 39
    },
    {
        id: 5,
        purpose: "Refinance",
        program: "FHA",
        creditBucket: "500-699",
        leader: "Village",
        rate: 5.99,
        price: 99.234,
        lockTerm: 45
    },
    {
        id: 6,
        purpose: "Refinance",
        program: "FHA",
        creditBucket: "500-699",
        leader: "Village",
        rate: 5.5,
        price: 76.344,
        lockTerm: 39
    },
    {
        id: 7,
        purpose: "Refinance",
        program: "FHA",
        creditBucket: "500-699",
        leader: "Village",
        rate: 6.03,
        price: 98.234,
        lockTerm: 45
    },
    {
        id: 8,
        purpose: "Refinance",
        program: "FHA",
        creditBucket: "500-699",
        leader: "Village",
        rate: 7.32,
        price: 23.972,
        lockTerm: 45
    },
    {
        id: 9,
        purpose: "Refinance",
        program: "FHA",
        creditBucket: "500-699",
        leader: "Village",
        rate: 7,
        price: 98.32,
        lockTerm: 39
    },
    {
        id: 10,
        purpose: "Refinance",
        program: "FHA",
        creditBucket: "500-699",
        leader: "Village",
        rate: 5.99,
        price: 99.234,
        lockTerm: 45
    }
];


const colums = [
    {
        lavel: "Purpose",
        key: "purpose"
    },
    {
        lavel: "Program",
        key: "program"
    },
    {
        lavel: "Credit Bucket",
        key: "creditBucket"
    },
    {
        lavel: "Leader",
        key: "leader"
    },
    {
        lavel: "Rate",
        key: "rate"
    },
    {
        lavel: "Price",
        key: "price"
    },
    {
        lavel: "Lock Term",
        key: "lockTerm"
    }
]

const RateSack = ({ selectedRates, handleRowClick }) => {

    return (
        <div className="table-responsive border-back rounded">
            <table className="table data-table mb-0">
                <thead className="table-color-heading">
                    <tr className="">
                        {colums.map((col, i) => (
                            <th index={i} scope="col">
                                {col.lavel}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rateStackData.map((row) => {
                        const isSelected = selectedRates.some(
                            (item) => item.id === row.id && item.rate === row.rate
                        );

                        return (
                            <tr
                                key={row.id}
                                className={`white-space-no-wrap ${isSelected ? "table-active" : ""}`}
                                onClick={() => handleRowClick(row)}
                            >
                                {colums.map((col) => (
                                    <td key={col.key}>{row[col.key]}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default RateSack
