import { configureStore } from '@reduxjs/toolkit';
import Mode from './mode/index';
import { apiBaseSlices } from '../api/baseAPI';

const store = configureStore({
  reducer: {
    mode: Mode,
    [apiBaseSlices.reducerPath]: apiBaseSlices.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiBaseSlices.middleware),
});

export default store;
