import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import RateSack from '../../../components/Lead/RateSack'
import LoanDetails from '../../../components/Lead/LoanDetails'
import CardWrapper from '../../../components/utility/CardwWrappper'
import RatePrice from '../../../components/Lead/RatePrice'


const fromInitialValus = {
    lonePurpose: "",
    loneProgram: "",
    financeTearm: "",
    lender: "",
    baseLoanAmount: "",
    cashOut: "",
    loanAmountFixed: null,
    reserveMounts: "",
    processingFee: "",
    revenueType: "",
    BPS: "",
    compensationType: "",
    lockTerm: "",
    parOptionAvailable: "",
    APOR: null,
    isPrice: "",
}

const LeadeditQuote = () => {
    const [formData, setFormData] = useState(fromInitialValus);
    const [selectedRates, setSelectedRates] = useState([]);
    const [isQuted, setIsQuted] = useState(false);

    const navigate = useNavigate();

    const handleRowClick = (rate) => {
        const isSelected = selectedRates.some((item) => item.id === rate.id && item.rate === rate.rate);

        if (isSelected) {
            // If the item is already selected, remove it
            setSelectedRates((prev) => prev.filter((item) => item !== rate));
        } else if (selectedRates.length < 5) {
            // Add the item if not already selected and limit is not reached
            setSelectedRates((prev) => [...prev, rate]);
        }
    };

    const checkQMSubmit = () => {
        setIsQuted(true)
    };

    const handlePriview = () => {
        console.log("selectedRates", selectedRates)
        console.log("formData", formData);

        navigate("/lead-view");
    }

    const handleSave = () => {
        
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/leads">Lead > Quote</Link></li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/leads" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>

                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Edit Lead</h4>
                    </Col>

                    <Col lg="12">
                        <div className='p-4 row'>
                            <div className='text-center col-sm-6 col-md-3'>
                                <h5 className='mb-1'>Borroer Name</h5>
                                <p>Reynaldo Elardo</p>
                            </div>
                            <div className='text-center col-sm-6 col-md-3'>
                                <h5 className='mb-1'>Priperty value</h5>
                                <p>$506532</p>
                            </div>
                            <div className='text-center col-sm-6 col-md-3'>
                                <h5 className='mb-1'>Loan Balance</h5>
                                <p>$756567</p>
                            </div>
                            <div className='text-center col-sm-6 col-md-3'>
                                <h5 className='mb-1'>Credit Score</h5>
                                <p>456</p>
                            </div>
                        </div>
                    </Col>

                    {/* loan details  */}
                    <Col lg="12">
                        <CardWrapper title="Lone Details">
                            <LoanDetails formData={formData} setFormData={setFormData} />
                        </CardWrapper>
                    </Col>

                    {/*  Rate Sack */}
                    <Col lg="12">
                        <CardWrapper title="Rate Sack">
                            <RateSack selectedRates={selectedRates} handleRowClick={handleRowClick} />
                        </CardWrapper>
                    </Col>

                    {/*  Rate Price */}
                    <Col lg="12">
                        <CardWrapper title="Rate Price">
                            <RatePrice selectedRates={selectedRates} isQuted={isQuted} />
                        </CardWrapper>
                    </Col>
                </Row>

                <div className="d-flex flex-wrap justify-content-end mt-0 mb-5">
                    <Button onClick={handleSave} variant="btn btn-success  font-weight-bold btn-sm py-2 px-3 mr-2">
                        Save
                    </Button>

                    {isQuted ?
                        <Button onClick={handlePriview} variant="btn btn-primary font-weight-bold btn-sm py-2 px-3">
                            Preview
                        </Button>
                        : <Button onClick={checkQMSubmit} variant="btn btn-primary font-weight-bold btn-sm py-2 px-3">
                            Check QM
                        </Button>
                    }
                </div>
            </Container>
        </>
    )
}

export default LeadeditQuote;
