import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../../components/Card";
import { connect } from "react-redux";
import { getDarkMode } from "../../../store/mode";

//img
import logo from "../../../assets/images/logo.png";
import darklogo from "../../../assets/images/logo-dark.png";

function mapStateToProps(state) {
  return {
    darkMode: getDarkMode(state),
  };
}

const initialValus = {
  firstName: '',
  lastName: '',
  email: '',
  passowrd: '',
  phone: '',
  confirmPassoeard: ''
}

const SignUp = (props) => {
  const [formData, setFormData] = useState(initialValus);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    console.log("Form Data:", formData);
  };

  return (
    <>
      <section className="login-content">
        <Container className="h-100">
          <Row className="align-items-center justify-content-center h-100">
            <Col md="7">
              <Card className="p-3">
                <Card.Body>
                  <h3 className="mb-3 font-weight-bold text-center">
                    Sign Up
                  </h3>

                  <Form onSubmit={onSubmit}>
                    <Row>
                      <Col md="12">
                        <Row>
                          <Col lg="6" md="12">
                            <Form.Group>
                              <Form.Label className="text-secondary">
                                First Name
                              </Form.Label>
                              <Form.Control
                                className="form-control"
                                type="firstName"
                                placeholder="Enter First Name"
                                required
                                value={formData.firstName}
                                onChange={(e) =>
                                  handleInputChange("firstName", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6" md="12">
                            <Form.Group>
                              <Form.Label className="text-secondary">
                                Last Name
                              </Form.Label>
                              <Form.Control
                                className="form-control"
                                type="lastName"
                                placeholder="Enter Last Name"
                                required
                                value={formData.lastName}
                                onChange={(e) =>
                                  handleInputChange("lastName", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="12">
                        <Row>
                          <Col lg="6" md="12" >
                            <Form.Group>
                              <Form.Label className="text-secondary">
                                Phone
                              </Form.Label>
                              <Form.Control
                                className="form-control"
                                type="phone"
                                placeholder="Enter Phone"
                                required
                                value={formData.phone}
                                onChange={(e) =>
                                  handleInputChange("phone", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6" md="12" >
                            <Form.Group>
                              <Form.Label className="text-secondary">
                                Email
                              </Form.Label>
                              <Form.Control
                                className="form-control"
                                type="email"
                                placeholder="Enter Email"
                                required
                                value={formData.email}
                                onChange={(e) =>
                                  handleInputChange("email", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="12">
                        <Row>
                          <Col lg="6" md="12" className="mt-2">
                            <Form.Group>
                              <Form.Label className="text-secondary">
                                Password
                              </Form.Label>
                              <Form.Control
                                className="form-control"
                                type="password"
                                placeholder="Enter Password"
                                required
                                value={formData.passowrd}
                                onChange={(e) =>
                                  handleInputChange("passowrd", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg="6" md="12" className="mt-2">
                            <Form.Group>
                              <Form.Label className="text-secondary">
                                Confirm Password
                              </Form.Label>
                              <Form.Control
                                className="form-control"
                                type="password"
                                placeholder="Enter Confirm Passoeard"
                                required
                                value={formData.confirmPassoeard}
                                onChange={(e) =>
                                  handleInputChange("confirmPassoeard", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="12" className="mt-2">
                        <Form.Check className="form-check-inline">
                          <div className="custom-control custom-checkbox custom-control-inline mb-3">
                            <Form.Check.Input
                              type="checkbox"
                              className="custom-control-input m-0"
                              id="inlineCheckbox1"
                              required
                            />
                            <Form.Check.Label
                              className="custom-control-label pl-2"
                              htmlFor="inlineCheckbox1"
                            >
                              I agree to the{" "}
                              <Link to="terms-of-service.html">
                                Terms of Service{" "}
                              </Link>{" "}
                              and{" "}
                              <Link to="privacy-policy.html">
                                Privacy Policy
                              </Link>
                            </Form.Check.Label>
                          </div>
                        </Form.Check>
                      </Col>
                    </Row>
                    <Button type="submit" className="btn btn-primary btn-block mt-2">
                      Create Account
                    </Button>
                    <div className="col-lg-12 mt-3">
                      <p className="mb-0 text-center">
                        Do you have an account?{" "}
                        <Link to="/auth/sign-in">Sign In</Link>
                      </p>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default connect(mapStateToProps)(SignUp);
